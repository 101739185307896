import React, {useEffect } from 'react';
import Slider from '@material-ui/core/Slider';
import { duration, withStyles} from '@material-ui/core/styles';

import "./progress_bar.css"
interface SeekBarProps {
  duration:number,
  compleated: number;
  seek:Function;
}

const TQSlider = withStyles({
  root: {
    color: '#2d823c',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);




/**
 * Primary UI component for user interaction
 */
export const SeekBar = ({
  duration,
  compleated,
  seek,
  ...props
}: SeekBarProps) => {
  
  useEffect(() => {
    console.log(duration)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[duration])
 

  useEffect(() => {
   // console.log((compleated * 100 )/ duration)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[compleated])


  const handleChange = (event:any, newValue:any) => {
    seek(duration * (newValue / 100 ));
  };

  
  return (
    <div className="seekbar">
      <div className="bar-box">
        <TQSlider value={(compleated * 100 )/ duration} onChange={handleChange} aria-labelledby="continuous-slider" />
        </div>
    </div>
  );
};


// <ProgressBar completed={Math.floor(compleated)} width={"800px"} customLabel="●" bgColor={"rgba(210,230,175,1)"} barContainerClassName={"bar-container"} labelClassName="bar-label"/>