
class Movie  {
  video_id: number;
  channel?:string;
  title:string;
  thumbnail_url:string ;

  constructor(video_id: number, title: string, thumbnail_url:string, channel?:string,) {
    this.video_id = video_id;
    this.channel = channel;
    this.title = title;
    this.thumbnail_url = thumbnail_url;
  }
};

export default Movie


