
import 'whatwg-fetch'

import Action from '../action/Action';

const MovieAction = {  

  get( id, channel, handler, errorHandler ){
    const channel_path = channel ? id + "/" + channel : id
    const path = "https://vimeo.com/api/oembed.json?url=https://vimeo.com/" + channel_path
    Action.fetchJson(path, "GET", {}, null,  handler, errorHandler)
  },

  //cookie.save('current_cart_id', data.id, { path: '/' })
  
}

export default MovieAction
