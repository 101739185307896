import React, { useState, useEffect } from 'react';
import Player from '@vimeo/player';

import './movie.css';

interface MovieProps {
  /**
   * id for vimeo
   */
  movie_id: number;

   /**
   * channel for vimeo
   */
   channel?: string;


  /**
   * is playing video
   */
  is_playing: boolean;
  

  /**
   * seeking to this time 
   */
  seek_time: number;


   /**
   * seeking to this time 
   */
   seek_dif_time: number;

  /**
   * resert SeekTime
   */
  resetSeekTime: () => void;

  /** 
  * play handelr
  *
  */
  playHandler :() => void

  /** 
  * pause handelr
  *
  */
  pauseHandler :() => void


   /** 
  * ended handelr
  *
  */
   endedHandler :() => void

   /** 
  * progress handelr
  *
  */
   updateProgressHandler:(d:Object) => void

   updateDurationHandler:(d:Number) => void
}

/**
 * Primary UI component for user interaction
 */
export const Movie = ({
  movie_id,
  channel,
  is_playing,
  seek_time,
  seek_dif_time,
  resetSeekTime,
  playHandler,
  pauseHandler,
  endedHandler,
  updateProgressHandler,
  updateDurationHandler,
  ...props
}: MovieProps) => {

  const [ player, setPlayer] = useState<Player | null | undefined >(null)
  const [ duration, setDuration] = useState(0)

  const channel_path = channel ? movie_id + "/" + channel : movie_id
  const options = {
    url: "https://vimeo.com/" + channel_path,
    width: 1013,
    height:570,
    autoplay:true,
    controls:false
  };

  useEffect(() => {

   if(is_playing === true && player){
      player.play()      
   }else if(is_playing === false && player){
    player.pause()      
 }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[is_playing])


  useEffect(() => {

    if(player && seek_dif_time === 10000000){
      player.setCurrentTime(0) 
      resetSeekTime()
    } else if(seek_dif_time !== 0 && player && duration > 0){
      player.getCurrentTime().then(function(time) {
        var target_time =  time + seek_dif_time
        if(target_time <= 0){
          target_time = 1
        }else if(target_time > duration){
          target_time = duration
        }
        player.setCurrentTime(target_time) 
        resetSeekTime()
      }); 
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   },[seek_dif_time])

   useEffect(() => {

    if(player && seek_time === 10000000){
      player.setCurrentTime(0) 
      resetSeekTime()
    } else if(seek_time !== 0 && player && duration > 0){
      player.getCurrentTime().then(function(time) {
        var target_time =  seek_time
        if(target_time > 0 && target_time < duration){
          player.setCurrentTime(target_time) 
          resetSeekTime()
        }else{
          player.setCurrentTime(1) 
          resetSeekTime()
        }
      }); 
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   },[seek_time])
  
   
   useEffect(() => {
    if(player){
    
      player.getDuration().then(function(duration){
        setDuration(duration)
        updateDurationHandler(duration)
      });
      player.on('play', function(data) {
        playHandler()
      });
      player.on('pause', function(data) {
        pauseHandler()
      });
      player.on('ended', function(data) {
        endedHandler()
      });
      player.on('timeupdate', function(data){
        updateProgressHandler(data)
      })
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[player])

  useEffect(() => {
    setPlayer(new Player("vimeo", options));    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
 
    <div id="vimeo"></div>
  );
};
